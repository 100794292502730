<template>
  <div class="docs">
    <div class="docs__nav">
      <a href="#docs" class="docs__nav-link">Документы</a>
      <a href="#comp" class="docs__nav-link">Компенсации</a>
      <a href="#conditions" class="docs__nav-link">Условия заезда</a>
      <a href="#structure" class="docs__nav-link">Cтруктура</a>
      <a href="#counselors" class="docs__nav-link">О вожатых</a>
      <a href="#nutrition" class="docs__nav-link">Питание</a>
      <a href="#safety" class="docs__nav-link">Безопасность</a>
    </div>
    <div class="docs__title" id="docs">ДОКУМЕНТЫ</div>
    <info
      class="docs__card"
      title="Для заезда в лагерь необходимо предоставить следующие документы и справки:"
      color="#F98419"
      backgroundColor="linear-gradient(258.58deg, #F98419 8.47%, #FF9C41 100%)"
    >
      <ul>
        <li class="docs__info-text">путевку*;</li>
        <li class="docs__info-text">
          медицинскую справку (форма 079/у), полученную не ранее, чем за 3 дня
          до заезда**;
        </li>
        <li class="docs__info-text">
          документ об отсутствии контактов с COVID-19 и эпидокружении ,
          полученный не ранее, чем за 3 дня до заезда***;
        </li>
        <li class="docs__info-text">
          копию свидетельства о рождении или паспорта;
        </li>
        <li class="docs__info-text">копию страхового медицинского полиса.</li>
        <li class="docs__info-text">
          распечатанное и заполненное
          <a
            class="docs__info-text-link"
            href="https://drive.google.com/file/d/1QFXs9NEDjusVu-zRT9vDTkFSyiWDSE3y/view?usp=sharing"
            target="_blank"
            >Медсогласие</a
          >
        </li>
        <li class="docs__info-text">
          прием в лагерь детей, которые не были вакцинированы согласно
          Национальному календарю профилактических прививок для детей, возможен
          только при предъявлении оформленного родителями официального
          документа-отказа, заверенного подписью уполномоченного врача и
          официальной печатью поликлиники. При отсутствии данного документа
          лагерь не имеет права принимать невакцинированного ребенка.
        </li>
      </ul>
      <span class="docs__sub-info"
        >Без указанных документов ребенок в СОЦ не допускается.<br/><br/>По возникшим медицинским вопросам и справкам вы можете получить информацию у нашего медицинского сотрудника Врача педиатра <a href="tel:+79869880433">+7 (986) 988-04-33</a></span
      >
      
      <!-- <br/><br/>По возникшим медицинским вопросам и справкам вы можете получить информацию у нашего медицинского сотрудника Шаюповой Натальи Валентиновны по телефону <a href="tel:+79093352117">+7 (909) 335-21-17</a> -->
    </info>
    <div class="docs__info">
      <p class="docs__text">
        * После полной оплаты путевки на ваш электронный адрес, указанный при
        регистрации в личном кабинете, придет письмо с электронной путевкой и
        чеком. Данную путевку необходимо распечатать и предоставить на заезде с
        остальным пакетом документов на ребенка.
      </p>
      <br />
      <p class="docs__text">
        ** Форма 079/У запрашивается у участкового педиатра в клинике
        (Медицинская справка на ребенка, отъезжающего в санаторный
        оздоровительный лагерь (форма 079/у) - это обязательный медицинский
        документ ребенка, который планирует посетить детский лагерь с целью
        отдыха. Справка заполняется медицинским работником
        амбулаторно-поликлинического учреждения или школы. Назначение справки -
        обеспечить медицинский персонал лагеря всей необходимой информацией о
        состоянии здоровья ребенка, прибывшего на отдых и оздоровление (о
        хронических заболеваниях, об АКДС, перенесенных инфекционных
        заболеваниях, физкультурной группе).
      </p>
      <p class="docs__text">
        Эти сведения дадут возможность работникам лагеря обеспечить правильный
        режим отдыха и оздоровительных мероприятий, подобрать индивидуальную
        физическую нагрузку для ребенка. Справка заполняется после медицинского
        осмотра ребенка. Медицинский работник поликлиники (школы) вносит записи
        на лицевой стороне справки. Обратная ее часть заполняется уже
        непосредственно медперсоналом оздоровительного заведения).
      </p>
      <br />
      <p class="docs__text">
        *** Справка об эпидокружении и отсутствии контакта с COVID-зараженными
        может быть выдана отдельно или вписана в форму 079/У.
      </p>
    </div>
    <div class="docs__title" id="comp">КОМПЕНСАЦИИ</div>
    <info
      class="docs__card"
      title="Информация по компенсации за путевку в лагерь от работодателя (если таковая предусмотрена работодателем)"
      color="#F98419"
      backgroundColor="rgb(186, 163, 228)"
    >
      <!-- <span class="docs__info-text">Для получения компенсации за путевку в лагерь от работодателя (если таковая предусмотрена работодателем), пришлите письмо на электронную почту sales-b@berezka64.ru со следующим содержанием:</span> -->
      <ul>
        <li class="docs__info-text">
          Вы можете самостоятельно распечатать
          <a
            class="docs__info-text-link"
            target="_blank"
            href="https://drive.google.com/file/d/1ZuTsGflpKmwtLcToS1VwiWDFxrxNYNMc/view?usp=sharing"
            >ДОГОВОР ОКАЗАНИЯ УСЛУГ</a
          >
          в 2-х экземплярах, заполнить и привезти на подпись в рабочее время
          отдела продаж;
        </li>
        <li class="docs__info-text">
          Распечатайте и заполните
          <a
            class="docs__info-text-link"
            target="_blank"
            href="https://drive.google.com/file/d/1JJ1hkgYPaP15BE-7o25veTiT6gWe7Z9g/view?usp=sharing"
            >СПРАВКУ О ПРЕБЫВАНИИ РЕБЕНКА В ЛАГЕРЕ</a
          >. Дата окончания смены является датой выдачи справки. Поставить
          печать на справку вы можете в рабочее время отдела продаж, не ранее
          2-х дней до окончания смены;
        </li>
        <li class="docs__info-text">
          Отрывным талоном является часть путевки, которая остается у вас после
          регистрации ребенка на заезде в лагерь;
        </li>
        <li class="docs__info-text">
          Подтверждением оплаты является чек об оплате, который приходит на вашу
          электронную почту сразу после оплаты путевки;
        </li>
        <li class="docs__info-text">
          Санитарно-эпидемиологическое заключение вы можете <a class="docs__info-text-link" target="_blank" href="https://drive.google.com/file/d/1iaKMcxjXnQdH1BVzW1Ds4AnM1qAGr4y9/view">СКАЧАТЬ</a> и распечатать;
        </li>
      </ul>
      <span class="docs__sub-info"
        >P.S.: пакет документов на компенсацию смотри выше.</span
      >
    </info>
    <info
      class="docs__card"
      title="Информация по компенсации за путевку в лагерь от правительства Саратовской области."
      color="#F98419"
      backgroundColor="rgb(186, 163, 228)"
    >
      <!-- <span class="docs__info-text">Для получения компенсации за путевку в лагерь от работодателя (если таковая предусмотрена работодателем), пришлите письмо на электронную почту sales-b@berezka64.ru со следующим содержанием:</span> -->
        <p class="docs__info-text">
          Компенсация предоставляется МНОГОДЕТНЫМ СЕМЬЯМ, независимо от финансового положения семьи. <br>
      Компенсируется часть путевки на каждого ребенка в семье, но только по 1 путевке в год за летний период 2024 года. <br>
      Компенсация в размере 50% от стоимости из расчета 1248 руб/сутки (по установленной среднесуточной расценке Министерства труда и соцразвития). <br>
      ИТОГО: <br>
      14 дней - 8736 руб. компенсации после пребывания в лагере; <br>
      21 день - 13104 руб. компенсации после пребывания в лагере<br>Уважаемые родители, уточняйте всю информацию по компенсациям только по телефонам горячей линии Минсоцразвития 
      98-26-54, 65-33-20 <br><br><br>
      P. S.: пакет документов на компенсацию смотри выше
          </p>
    </info>
  </div>
</template>

<script>
import Info from "@/components/elements/Info";
export default {
  components: {
    Info,
  },
};
</script>

<style lang="scss" scoped>
.docs {
  padding: 48px 0 62px;
  max-width: 1080px;
  margin: 0 auto;
  @media screen and (max-width: 1300px) {
    max-width: 620px;
  }
  @media screen and (max-width: 680px) {
    max-width: 300px;
  }
  &__title {
    font-family: RF Dewi Expanded;
    font-size: 64px;
    font-weight: 900;
    line-height: 77px;
    margin: 34px 0 54px;
    @media screen and (max-width: 1300px) {
      font-size: 46px;
      line-height: 55px;
    }
    @media screen and (max-width: 680px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &__nav {
    &-link {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      display: inline-block;
      margin: 8px 12px 0 0;
      border: 2px solid;
      padding: 12px 24px;
      border-radius: 50px;
      text-decoration: none;
      transition: background-color 0.3s, color 0.3s;
      @media screen and (max-width: 1300px) {
        font-size: 14px;
        line-height: 18px;
        padding: 8px 14px;
        margin: 0 12px 18px 0;
      }
      &:nth-child(1) {
        color: #9dcefc;
        border-color: #9dcefc;
        &:hover {
          background-color: #9dcefc;
          color: white;
        }
      }
      &:nth-child(2) {
        color: #f98419;
        border-color: #f98419;
        &:hover {
          background-color: #f98419;
          color: white;
        }
      }
      &:nth-child(3) {
        color: #fb6f8a;
        border-color: #fb6f8a;
        &:hover {
          background-color: #fb6f8a;
          color: white;
        }
      }
      &:nth-child(4) {
        color: #5ec0a9;
        border-color: #5ec0a9;
        &:hover {
          background-color: #5ec0a9;
          color: white;
        }
      }
      &:nth-child(5) {
        color: #676fb2;
        border-color: #676fb2;
        &:hover {
          background-color: #676fb2;
          color: white;
        }
      }
      &:nth-child(6) {
        color: #3fd33c;
        border-color: #3fd33c;
        &:hover {
          background-color: #3fd33c;
          color: white;
        }
      }
      &:nth-child(7) {
        color: #1a66ac;
        border-color: #1a66ac;
        &:hover {
          background-color: #1a66ac;
          color: white;
        }
      }
    }
  }
  &__info {
    padding: 42px 0 0;
  }
  &__card {
    ul {
      padding-inline-start: 20px;
    }
  }
  &__text {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    @media screen and (max-width: 680px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__info-text {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    @media screen and (max-width: 680px) {
      font-size: 14px;
      line-height: 18px;
    }
    &-link {
      color: black;
      font-weight: bold;
    }
  }
  &__sub-info {
    display: inline-block;
    width: 100%;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    border-top: 1px solid #dfdfdf;
    padding: 16px 0 0;
    a {
      color: black;
    }
    @media screen and (max-width: 680px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
</style>
